export default AppFooter;

function AppFooter({ appName }) {
   return (
     <footer className="App-footer">
       <p>0.1 - Alpha UniversE</p>
       <p>&copy; {new Date().getFullYear()} { appName }. All rights reserved.</p>
     </footer>
   );
}
