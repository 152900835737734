import React, { useState, useEffect } from 'react';

import axios from 'axios';
import ReactMarkdown from 'react-markdown';

export default AppMain;

function AppMain() {

  const showTranslatedText = "显示简体中文翻译";
  const hideTranslatedText = "显示原文";

  const [articleCreatedTime, setArticleCreatedTime] = useState("");
  const [articleBriefing, setArticleBriefing] = useState("");
  // const [translatedArticleBriefingCreatedTime, setTranslatedArticleBriefingCreatedTime] = useState("");
  const [translatedArticleBriefing, setTranslatedArticleBriefing] = useState("");

  const [showTranslated, setShowTranslated] = useState(false);

  // State to control the display of full content
  const [showFullContent, setShowFullContent] = useState(false);

  const [articleBriefingsList, setArticleBriefingsList] = useState([]);
  const [articleBriefingsCreatedTimeList, setArticleBriefingsCreatedTimeList] = useState([]);
  // const [translatedArticleBriefingsCreatedTimeList, setTranslatedArticleBriefingsCreatedTimeList] = useState([]);
  const [translatedArticleBriefingsList, setTranslatedArticleBriefingsList] = useState([]);

  const snippetCount = 10;

  // // Define the length of the preview snippet
  // const snippetLength = 100;

  // // Obtain the snippet from the full content
  // const contentSnippet = articleBriefing && articleBriefing.length > snippetLength
  //   ? articleBriefing.substring(0, snippetLength) + '...'
  //   : articleBriefing;


  useEffect(() => {
    // 拉取数据的函数
    async function fetchData() {
      try {
        // const response = await fetch("https://news-backend.aidehub.com/briefings/latest");
        const response = await axios.get("https://news-backend.aidehub.com/briefings_list/latest/" + snippetCount.toString());

        // Set the created time and the briefing content for the first article briefing
        
        const data = response.data[0];

        // console.info("response", response);

        const AritcleCreatedTime = data.created_at;
        
        // const formattedArticleBriefing = data.article_briefing_wt_wt.replace("/\n/g", "\n");
        const ArticleBriefing = data.article_briefing_wt_wt;

        setArticleCreatedTime(AritcleCreatedTime);
        // 设置文章摘要到state
        setArticleBriefing(ArticleBriefing);

        
        // Set the created time list and the briefing content list for the remaining article briefings

        const briefings_list = response.data.slice(1).map(item => item.article_briefing_wt_wt);
        const briefings_created_time_list = response.data.slice(1).map(item => item.created_at);

        setArticleBriefingsList(briefings_list);
        setArticleBriefingsCreatedTimeList(briefings_created_time_list);
        
      } catch (error) {
        // Use error.message to get a textual description of the error
        console.error("Error fetching data: ", error.message);
        setArticleBriefing(`Failed to load the article briefing: ${error.message}`);
      }
    }

    async function fetchTranslatedData() {
      try {
        // const response = await fetch("https://news-backend.aidehub.com/translated_briefings/latest");
        // const data = await response.json();

        const response = await axios.get("https://news-backend.aidehub.com/translated_briefings_list/latest/" + snippetCount.toString());

        
        // Set the created time and the transalted briefing content for the first translated article briefing
        
        const data = response.data[0];

        // console.info("response", response);

        // const TranslatedAritcleCreatedTime = data.created_at;
        
        // const formattedArticleBriefing = data.article_briefing_wt_wt.replace("/\n/g", "\n");
        const TranslatedArticleBriefing = data.article_briefing_wt_wt_translated_cn_szh;

        // setArticleCreatedTime(AritcleCreatedTime);
        
        // 设置文章摘要到state
        setTranslatedArticleBriefing(TranslatedArticleBriefing);


        // Set the created time list and the translated briefing content list for the remaining translated article briefings

        // const translated_briefings_created_time_list = response.data.slice(1).map(item => item.created_at);
        const translated_briefings_list = response.data.slice(1).map(item => item.article_briefing_wt_wt_translated_cn_szh);

        // setTranslatedArticleBriefingsCreatedTimeList(translated_briefings_created_time_list);
        setTranslatedArticleBriefingsList(translated_briefings_list);

      } catch (error) {
        // Use error.message to get a textual description of the error
        console.error("Error fetching data: ", error.message);
        setTranslatedArticleBriefing(`Failed to load the article briefing: ${error.message}`);
      }
    }
    

  //   async function fetchData() {
  //     try {
  //       const response = await fetch("https://news-backend.aidehub.com/briefings/latest");

  //       // Check if the response was ok (status in the range 200-299)
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       // Check the Content-Type to be sure we're parsing JSON
  //       const contentType = response.headers.get('Content-Type');
  //       if (!contentType || !contentType.includes('application/json')) {
  //         throw new TypeError("Oops, we haven't got JSON!");
  //       }
  //       const data = await response.json();
  //       console.info("response", response);
  //       // Set the article briefing if available
  //       if (data && data.article_briefing_wt_wt) {
  //         setArticleBriefing(data.article_briefing_wt_wt);
  //       } else {
  //         setArticleBriefing("Article briefing not available in the response.");
  //       }
  //     } catch (error) {
  //       // Use error.message to get a textual description of the error
  //       console.error("Error fetching data: ", error.message);
  //       setArticleBriefing(`Failed to load the article briefing: ${error.message}`);
  //     }
  //   }
    
    // 调用函数以拉取数据
    fetchData();
    fetchTranslatedData();
    
  }, []); // 空依赖数组表示这个effect只在组件mount时运行一次


  // useEffect(() => {
  //   // 拉取数据的函数
  //   async function fetchData() {
  //     try {
  //       // const response = await axios.get("https://news-backend.aidehub.com/briefings/latest");
  //       let response = await axios.get("https://news-backend.aidehub.com/briefings/latest");
  //       // 设置文章摘要到state
  //       if (response.data && response.data.article_briefing_wt_wt) {
  //         setArticleBriefing(response.data.article_briefing_wt_wt);
  //       } else {
  //         setArticleBriefing("Article briefing not available in the response.");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data: ", error.message);
  //       setArticleBriefing(`Failed to load the article briefing: ${error.message}`);
  //     }
  //   }

  //   // 调用函数以拉取数据
  //   fetchData();
  // }, []); // 空依赖数组表示这个effect只在组件mount时运行一次


  // Define the length of the preview snippet
  const snippetLength = 600;

  // Logic for combining showTranslated and showFullContent
  let displayText = "";
  if (showTranslated) {
    displayText = showFullContent ? translatedArticleBriefing : translatedArticleBriefing.substring(0, snippetLength) + '...';
  } else {
    displayText = showFullContent ? articleBriefing : articleBriefing.substring(0,snippetLength) + '...';
  }


  // useEffect(() => {

    // async function fetchArticleBriefingsList() {
      // try {
        // const response = await axios.get("https://news-backend.aidehub.com/briefings_list/latest/" + snippetCount.toString());
        // const briefings_list = response.data.slice(1).map(item => item.article_briefing_wt_wt);
        // const briefings_created_time_list = response.data.slice(1).map(item => item.created_at);

        // setArticleBriefingsList(briefings_list);
        // setArticleBriefingsCreatedTimeList(briefings_created_time_list);

      // } catch (error) {
        // console.error("Error fetching data: ", error.message);
      // }
    // }

    // async function fetchTranslatedArticleBriefingsList() {
      // try {
        // const response = await axios.get("https://news-backend.aidehub.com/translated_briefings_list/latest/" + snippetCount.toString());
        // const translated_briefings_list = response.data.slice(1).map(item => item.article_briefing_wt_wt_translated_cn_szh);

        // setTranslatedArticleBriefingsList(translated_briefings_list);

      // } catch (error) {
        // console.error("Error fetching data: ", error.message);
      // }
    // }

    // fetchArticleBriefingsList();
    // fetchTranslatedArticleBriefingsList();
  // }, []);

  // ArticleBriefingItem component
  function ArticleBriefingItem({ briefing, translatedBriefing, createdTime }) {

    const [showTranslated, setShowTranslated] = useState(false);
    const [showFullContent, setShowFullContent] = useState(false);

    const snippetLength = 300;
    let displayText = showTranslated
      ? showFullContent ? translatedBriefing : translatedBriefing.substring(0, snippetLength) + '...'
      : showFullContent ? briefing : briefing.substring(0, snippetLength) + '...';

    return (
      <div className="Article-briefing-item">

        <div className="Article-briefing-content">
          <button
            onClick={() => setShowTranslated(!showTranslated)}
            className="show-translated-button"
          >
            { showTranslated ? "显示原文" : "显示简体中文翻译" }
          </button>

          <div className="Article-briefing-created-time">
            <p>{createdTime}</p>
          </div>

          <ReactMarkdown>
            {displayText}
          </ReactMarkdown>
          
          <button 
            className="Show-more-button"
            onClick={() => setShowFullContent(!showFullContent)}
          >
            {showFullContent ? 'Less' : 'More'}
          </button>

        </div>
      </div>
    );
  }
  
  return (
    <main className="App-main">
      <div className="Article-briefing">

        <div className="Article-briefing-content">
          <button
            onClick={() => setShowTranslated(!showTranslated)}
            className="show-translated-button"
          >
            { showTranslated ? hideTranslatedText : showTranslatedText }
          </button>

          <div className="Article-created-time">
            <p>{articleCreatedTime}</p>
          </div>
          
          <ReactMarkdown>
            {displayText}
          </ReactMarkdown>
  
          <button 
            className="Show-more-button"
            onClick={() => setShowFullContent(!showFullContent)}
            style={{
            }}
          >
            {showFullContent ? 'Less' : 'More'}
          </button>
  
        </div>
      </div>
  
      <div className="Article-briefings-list">
        {/* { articleBriefingsList.map((articleBriefing, index) => (
          <div key={index} className="Article-briefing-item">
  
            <div className="Article-briefing-created-time">
              <p>{articleBriefingsCreatedTimeList[index]}</p>
            </div>
  
            <div className="Article-briefing-content">
              <ReactMarkdown>
                {articleBriefing}
              </ReactMarkdown>
            </div>
  
  
          </div>
        )) } */}
  
        {articleBriefingsList.map((articleBriefing, index) => (
          <ArticleBriefingItem
            key={index}
            createdTime={articleBriefingsCreatedTimeList[index]}
            briefing={articleBriefing}
            translatedBriefing={translatedArticleBriefingsList[index]} // Assuming you have a list of translated briefings
          />
        ))}
  
      </div>
  
    </main>

  )
}
