import React from 'react'

// import axios from 'axios'
// import ReactMarkdown from 'react-markdown'

// import logo from './logo.svg';

import './App.css';
import AppHeader from './components/AppHeader';
import AppMain from './components/AppMain';
import AppFooter from './components/AppFooter';


export default App;

function App() {

  const appName = "AIde News";
  
  return (
    <div className="App">

      <AppHeader appName={appName} />

      <AppMain />
      
      <AppFooter appName={appName} />
      
    </div>
  );
}
