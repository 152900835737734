export default AppHeader;

function AppHeader( { appName }) {
   return (
     <header className="App-header">
       {/* <img src={logo} className="App-logo" alt="logo" />
       <p>
         Edit <code>src/App.js</code> and save to reload.
       </p>
       <a
         className="App-link"
         href="https://reactjs.org"
         target="_blank"
         rel="noopener noreferrer"
       >
         Learn React
       </a> */}

       <h1>{ appName }</h1>
       
       <p style={{ fontSize: 'smaller' }}>AI Selected News</p>
       
       <p style={{ fontSize: 'smaller' }}>
         An AIde from <a className="App-link-header" href="https://aidehub.com">AIdeHub.com</a>
       </p>
       
     </header>
   );
}
